<template>
    <div class="container-fluid">
        <div class="col-md-2 hidden-sm hidden-xs"></div>
        <div class="col-md-8">
            <div class="row" style="padding-bottom:50px">
                <div class="col-sm-12" v-if="!load">
                   <h4 class="text-center">{{ $t('leaderboard.ends') }} {{ $moment().endOf('isoWeek').format('yyyy-MM-DD HH:mm:ss') }}</h4>
                    <div class="champions">
                            <div class="">
                                <img src="/images/2r.png" alt="2chmap" class="img-champ">
                                <p>{{ transform(leaderboard[1].user_id) }}</p>
                                <p>BIF 150,000</p>
                            </div>
                            <div class="">
                                <img src="/images/champr.png" alt="2chmap" class="img-champ">
                                <p>{{ transform(leaderboard[0].user_id) }}</p>
                                <p>BIF 200,000</p>
                            </div>
                            <div class="">
                                <img src="/images/3r.png" alt="2chmap" class="img-champ">
                                <p>{{ transform(leaderboard[2].user_id) }}</p>
                                <p>BIF 100,000</p>
                            </div>
                    </div>
                </div>
                <div class="col-sm-12" v-if="!load">
                    <div class="tabs">
                        <div class="div-tab" :class="{ 'select' : info == 'table'}" @click="toggleTab('table')">{{ $t('leaderboard.top') }}</div>
                        <div class="div-tab" :class="{ 'select' : info != 'table'}" @click="toggleTab('d')">{{ $t('promotions.how-to-participate') }}</div>
                    </div>
                    <div class="content">
                        <template v-if="info == 'table'">
                            <div class="table-con">
                                <div class="table-responsive list" v-if="leaderboard" style="margin-top: 10px;">          
                                <table class="table">
                                    <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>{{ $t('leaderboard.mobile') }}</th>
                                        <th>{{ $t('leaderboard.bets') }}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="( l,x) in leaderboard" :key="`ld${x}`">
                                            <td>{{ x+1 }}</td>
                                            <td>{{ transform(l.user_id) }}</td>
                                            <td>{{ getPercent(l.total) }}%</td>
                                        </tr>
                                    </tbody>
                                </table>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <div class="rules">
                                <h4>{{ $t('leaderboard.title') }}</h4>
                                <p class="bl">{{ $t('promotions.how-to-participate') }}</p>
                                <p>{{ $t('promotions.betshare-p1') }}</p>
                                <p>{{ $t('promotions.betshare-p2') }}</p>

                                <p class="bl">{{ $t('leaderboard.how-it-works') }}</p>
                                <p class="">{{ $t('leaderboard.app') }}</p>

                                <ul style="list-style-type: lower-roman;">
                                    <li>{{ $t('leaderboard.li-1') }}</li>
                                    <li>{{ $t('leaderboard.li-2') }}</li>
                                    <li>{{ $t('leaderboard.li-3') }}</li>
                                    <li>{{ $t('leaderboard.li-4') }}</li>
                                    <li>{{ $t('leaderboard.li-5') }}</li>
                                    <li>{{ $t('leaderboard.li-6') }}</li>
                                </ul>

                                <h6>{{ $t('leaderboard.ussd') }}</h6>
                                <ul style="list-style-type: lower-roman;">
                                    <li>{{ $t('leaderboard.li-1') }}</li>
                                </ul>

                                <h6>{{ $t('leaderboard.us-t') }}</h6>
                                <p class="bl">{{ $t('leaderboard.us-p') }}</p>
                                <ul style="list-style-type: lower-roman;">
                                    <li>{{ $t('leaderboard.us-p1') }}</li>
                                    <li>{{ $t('leaderboard.us-p2') }}</li>
                                    <li>{{ $t('leaderboard.us-p3') }}</li>
                                    <li>{{ $t('leaderboard.us-p4') }}</li>
                                    <li>{{ $t('leaderboard.us-p5') }}</li>
                                    <li>{{ $t('leaderboard.us-p6') }}</li>
                                </ul>
                                <p class="">{{ $t('leaderboard.us-p7') }}</p>
                            </div>
                        </template>
                    </div>
                </div>
                <Loader v-if="load" />
            </div>
        </div>
        <div class="col-md-2 hidden-sm hidden-xs"></div>
    </div>
</template>
<script>
import {mapGetters} from "vuex";
import Loader from "@/components/sections/loader.vue";

export default {
    data() {
        return {
            publicPath: process.env.BASE_URL || '/',
            info:'table',
            load:false
        }
    },
    components: {
        Loader
    },
    computed: {
        ...mapGetters(['leaderboard'])
    },
    methods: {
        thisMounted: async function() {
            this.load = true;
            await this.$store.dispatch("fetch_leaderboard");
            this.load = false;
        },
        transform(arg) {
            let dd = String(arg);
            return dd.replace(dd.substring(5,8), 'XXX')
        },
        toggleTab: function (arg) {
            this.info = arg;
        },
        getPercent(num) {
            let res = (num * 1) / 4;
            return res;
        }
    },
    mounted() {
        this.thisMounted();
    }
}
</script>
<style scoped>
.champions {
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    margin-bottom: 10px;
}
.img-champ {
    width: 100%;
}
.tabs {
    display: flex;
    justify-content: center;
    gap:10px;
}
.div-tab {
    background-color: var(--bg-orange);
    padding: 6px 20px;
    flex: 1;
    text-align: center;
    cursor: pointer;
}
.div-tab.select{
    background-color: var(--bg-blue);
}
.rules {
    background-color: var(--bg-dark-medium);
    border-radius: 4px;
    padding: 10px;
    margin-top: 10px;
}
p {
    margin-bottom: 3px;
    font-size: 13px;
}
p.bl {
    font-weight: 500;
}
.content {
    margin-top: 20px;
}
/* ------table------- */
.table-con {
    background-color: var(--bg-dark-medium);
    max-height: 500px;
    overflow-y: scroll;
}
tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
}
.table thead tr th:first-child {
    width: 5%;
    padding-left: 1em;
}
.table thead tr th {
    text-align: left;
    border: none;
    border-bottom: #ddd solid 1px;
    background: #f6f7f8;
    color: var(--bg-dark-medium);
    font-weight: 500;
    font-size: .9em;
    padding: .5em .75em;
}
.table thead tr th:last-child {
    text-align: right;
    padding-right: 1em;
}
.table tbody tr td:first-child {
    width: 5%;
    padding-left: 1em;
}
.table tbody tr td {
    border-bottom: var(--border-color) solid 1px;
    color: #f4f3f3;
    padding: .65em .75em;
}
.table tbody tr td:last-child {
    text-align: right;
    padding-right: 1em;
}
</style>